export const globalHeaders = { "Content-Type": "application/json" };
export const globalMultipartHeaders = { "Content-Type": "multipart/form-data" };


export function setGlobalHeader(name, value) {
  globalHeaders[name] = value;
}

function isValidHttpCode(code) {
  if (code >= 200 && code < 400) {
    return true;
  }
  return false;
}
const http = {
  isValidHttpCode,
};

export default http;
