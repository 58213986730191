import React from "react";
import { ButtonStyled } from "@nike/nike-design-system-components";

const Button = (props) => {

    return (
        <div>
            <ButtonStyled
                appearance={props.appearance}
                background={props.background}
                size={props.size}
                disabled={props.disabled}
                classes={props.classes}
                className={props.className}
                type="button"
                disableRipple={false}
                children={props.name}
                onClick={props.onClick}
            />
        </div>
    )
}

export default Button;