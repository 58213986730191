import "./component.css";
import "bootstrap/dist/css/bootstrap.css";
import "rsuite/dist/rsuite.min.css";
import Button from "./Button";

    const LoggedOut = () => {
  
    return (
        <div>
        
      <div className="center">
      
            You have been logged out from application.
           Close the browser to log out from Okta session.
      
        <div className="action-component-btn button margin-right-20px " >
                <Button className="action-component-btn button margin-right-20px"
                    name={"LOGIN"}
                    classes={{}}
                    appearance="primary"
                    background="light"
                    size="small"
                    type="button"
                    disableRipple={false}
                    onClick={(event) => (window.location.href = "/")}
                /></div>
      </div></div>
     
    );
  }
export default  LoggedOut;