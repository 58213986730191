import axios from "axios";
import http from "../utilities/http";
import { globalHeaders , globalMultipartHeaders} from "../utilities/http";
import API_INTERFACE_URI from "../constants/uri.constants";

const baseService = {
  post,
  getStatus,
  getBulkStatus,
};

async function getStatus(payload) {
  const apiUrl = API_INTERFACE_URI.GET_STATUS;
  console.log("API URL : "+apiUrl);
  const response = await post(apiUrl, globalHeaders, payload);
  console.log(response);
  if(response.status === 200) {
    return {
      status:response.status,
      data: response.data,
    }
  } else {
    return {
      status:response.status,
      error: "unable to get status",
      data:response.data,
    }
  }
}

async function getBulkStatus(payload) {
  const apiUrl = API_INTERFACE_URI.TRACK_BULK_STATUS;
  console.log("API URL : "+apiUrl);
  const responseType = 'arraybuffer'
  const response = await postWithResponseType(apiUrl, globalMultipartHeaders, payload, responseType);
   if(response.status === 200) {
    return response
  } else {
    return {
      status:response.status,
      error: "unable to get status",
      data:response.data,
    }
  }
}


async function post(url, headers, body) {
  try {
    const response = await axios.post(url, body, {
      headers: headers,
    });
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
}

async function postWithResponseType(url, headers, body, responseType) {
  try {
    const response = await axios.post(url, body, {
      headers: headers,
      responseType: responseType
    });
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
}

function handleError(error) {
  let errorResponse = "";
  if (axios.isCancel(error)) {
    errorResponse = error.message;
  } else {
    errorResponse = error.response
      ? error.response
      : { response: { status: 500 } };
  }
  return Promise.reject(errorResponse);
}

function handleResponse(response) {
  if (!http.isValidHttpCode(response.status)) {
    return Promise.reject(response);
  }
  return response;
}


export default baseService;
