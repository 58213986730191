import React from "react";
import { TextFieldStyled } from "@nike/nike-design-system-components";

const TextInput = props => {

    return <TextFieldStyled
        classes={{input: 'text-field-input', label: 'text-field-label', inputFieldSet: 'text-field-container'}}
        // error={props.error}
        className="text-field-container"
        // errorMessage={props.errorMessage}
        id={props.id}
        label={props.label}
        // maxLength={props.maxLength}
        // minLength={props.minLength}
        name={props.name}
        placeholder={props.placeholder}
        requiredIndicator={props.required ? '*' : ''}
        type={props.type}
        value={props.value}
        onChange={props.onChange}
        // onBlur={props.onBlur}
        // onKeyUp={props.onKeyUp}
    />
}

export default TextInput;