import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const formatKey = (key) => {
  return key
    .split(/(?=[A-Z])/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const formattedorderno = (orderNumbers) => {
  if (Array.isArray(orderNumbers)) {
    return orderNumbers.join(",");
  }
};

const DataTable = ({ tabledata }) => {
  const keys = Object.keys(tabledata[0]);

  return (
    <div style={{ display: "flex", minWidth: "fit-content" }}>
      <div style={{ minWidth: "200px" }}>
        <table stripped bordered hover responsive>
          <tbody>
            {keys.map((key, index) => (
              <tr key={index}>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    fontWeight: "bolder",
                    color: "dark",
                    fontFamily: "-moz-initial",
                  }}
                >
                  {formatKey(key)}
                </td>
                {tabledata.map((item, itemIndex) => (
                  <td
                    style={{ border: "1px solid black", padding: "8px" }}
                    key={itemIndex}
                  >
                    {key === "orderNumber"
                      ? formattedorderno(item[key])
                      : item[key]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DataTable;
