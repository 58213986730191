import './App.css';
import React, { useEffect, useState } from "react";
import { AegisClient, useAuth } from "@nike/aegis-auth-react";
import config from './config/config';
import { setGlobalHeader } from './utilities/http';
import { Route, Routes } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Footer from './components/Footer';
import HomePage from './components/HomePage';
import LoggedOut from './components/LoggedOut';
import DBAudit from './components/DBAudit';
const client = new AegisClient({
  qa: !config.isProd,
  ...config.oAuth,
});

function App() {
  const navigate = useNavigate();

  const { loginComplete, isLoggedIn, clearLogin } = useAuth(client);
  const [userToken, setUserToken] = useState();
  const [loggedInUser, setLoggedInUser] = useState();
  const [displayLogout, setDisplayLogout] = useState(true);
  const [isPowerRole, setIsPowerRole] = useState(false);
  const [adGroups, setAdGroups] = useState();

  useEffect(() => {
    async function handleLogin(obj) {
      const { accessToken } = obj;
      setGlobalHeader("Authorization", `Bearer ${accessToken.accessToken}`);
      setUserToken(accessToken.accessToken);
      setDisplayLogout(true);
      setLoggedInUser(accessToken.claims.sub);
      setAdGroups(accessToken.claims.groups);

    }

    client.on("login:success", handleLogin);
    client.on("renew:access:success", handleLogin);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!loginComplete || !userToken) return <faSpinner show={true} />;
  if (!isLoggedIn) return <LoggedOut />;

  function handleLogout() {
    setGlobalHeader("Authorization", null);
    clearLogin();
    client.logout();
    setDisplayLogout(false);
    navigate('/logout', {
      state: {       
      },
    });
  }

  return (
    <div className="App">
      <Routes>
      <Route
          exact
          path="/"
          element={<HomePage
            loggedInUser={loggedInUser}
            setIsPowerRole={setIsPowerRole}
            handleLogout={handleLogout}
            displayLogout={displayLogout}

            />}         
        />
        <Route
          exact
          path="/dbaudit"
          element={<DBAudit
            loggedInUser={loggedInUser}
            handleLogout={handleLogout}
            displayLogout={displayLogout}
            isPowerRole={isPowerRole}

            />}         
        />
        <Route exact path="/logout" element={<LoggedOut/>}></Route>
         </Routes>
         <Footer/>
    </div>
  );
}


export default App;
