import merge from "deepmerge";

const base = {
    appFullName: "OSC UI",
    appName: "OSC UI",
    description: "OSC UI for DB Audit ",
    isProd: false,
    oAuth: {
      clientId: "nike.node.osc-ui",
      redirectUri: window.location.origin,
      userInfo:
        "https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7/v1/userinfo",
      scopes: [
        "node_management:osc.dbaudit::read: profile openid email",
      ],
    },
  },
  local = merge(base, {
    // apiUrl:Test API url
    apiUrl: "http://localhost:8080",
  }),
  dev = merge(base, {
    apiUrl: "https://eu-node-osc-dev.nodemanagement-test.nikecloud.com",
  }),
  prod = merge(base, {
    // apiUrl:production API url
    apiUrl: " ",

    isProd: true,
  }),
  test = merge(base, {
    // apiUrl:Test API url
    apiUrl: "https://na-node-osc-test.nodemanagement-test.nikecloud.com",
  }),
  stage = merge(base, {
    // apiUrl:Stage API url
    apiUrl: "http://localhost:8080",
  });

let env = dev;
// if (
//   window.location.origin.includes("tms-eligibility-prod.nike.com") ||
//   window.location.origin.includes("tms-eligibility-prod.nodemanagement-prod.nikecloud.com")
// ) {
//   env = prod;
// } else 
if (
  window.location.origin.includes("osc-ui-dev.nodemanagement-test.nikecloud.com") 
  // window.location.origin.includes("tms-eligibility-dev.nike.com")
) {
  env = dev;
}
 else if (
  window.location.origin.includes("localhost:3000")
) {
  env = local;
}
else if (
  window.location.origin.includes("osc-ui-test.nodemanagement-test.nikecloud.com") 
  // window.location.origin.includes("tms-eligibility-test.nike.com")
) {
  env = test;
} 
const config = env;
export default config;
