import React, { useEffect, useState } from "react";
import Button from "./Button";
import "./component.css";
import Logo from '../asserts/images/nike-logo.png';
import { useNavigate } from "react-router-dom";
function Header({   handleLogout, displayLogout, name, loggedInUser, isHomePage}) {
  const [dpText, setDpText] = useState('');
  const [userName, setUserName] = useState('');
  const navigate = useNavigate();


  useEffect(() => {
    // if (loggedInUser) {
      const dpTextArray = loggedInUser?.split('.');
      const dp = dpTextArray[0][0].toUpperCase() + dpTextArray[1][0];
      setUserName(`${dpTextArray[0]}, ${dpTextArray[1].split('@')[0]}`)
      setDpText(dp);
    // }

  }, [loggedInUser])
  return (
    <div className="header">
      <div>
        <div className="div-align-center margin-left-neg-1px">
          <div className="">
            <img src={Logo} className='nike-logo-image' alt=""></img>
            {name ? <span className="epic-display-4 osc-title">Node OSC ({name})</span> :
              <span className="epic-display-4 osc-title">Node OSC</span>}
          </div>
            <div className="div-align-center-child">
            <div className="user-dp">{dpText}</div>
            <div className="div-login-details-child">
              <span className="user-name">{userName}</span>
              {displayLogout && (
                <div className="logout-container">
                  <Button  name={"LogOut"}
                    classes={{}}
                    appearance="primary"
                    background="dark"
                    size="small"                
                    type="button"
                    disableRipple={false}
                    onClick={handleLogout}/>
                </div>
              )}
            </div>

          </div>
        </div>
        </div>
      </div>
 
  );
}
export default Header;
