import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {NikeDesignSystemProvider, DropDownStyled } from "@nike/nike-design-system-components";
import "./component.css";
import "bootstrap/dist/css/bootstrap.css";
import "rsuite/dist/rsuite.min.css";
import Header from "./Header";
import Button from "./Button";

const HomePage = (props) => {
  const [selectedOption, setSelectedOption] = useState(''); 

  const navigate = useNavigate();

  const handleValue = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleNavigate = () => {
    if (selectedOption === "Track Status") {
      navigate("/dbaudit");
    } 
  };

  const dropDownOptions = [
    { label: "Track Status", value: "Track Status" },
  ];

  return (
    <div>
      <Header
      loggedInUser={props.loggedInUser}
      handleLogout={props.handleLogout}
      displayLogout={props.displayLogout}
      isHomePage={true}
      />
      <div className="display-flex justify-content-center">
    <div className="modal-field-container .nds-dropdown-select-container">
    <div className="center">
      <NikeDesignSystemProvider>
       
        <DropDownStyled
          name="select"
          id="select"
          value={selectedOption}
          requiredIndicator="*"
          placeholder="Select from below options"
          error={false}
          autoComplete="off"
          disabled={false}
          supportText="This dropdown will have the list of all Functions"
          options={dropDownOptions}
          errorMessage="Error message"
          onChange={handleValue}
          ariaLive="polite" 
        />
       
       <div className="display-flex justify-content-center">
       <div className="custom-button">
                <Button
                    name={"Continue"}
                    classes={{customButtom: true}}
                    appearance="primary"
                    background="light"
                    size="small"
                    disabled={selectedOption.trim()===""}
                    type="button"
                    disableRipple={false}
                    onClick={handleNavigate}
                /></div></div>
      </NikeDesignSystemProvider>
    </div>
    </div>
    </div>
    </div>
  );
};


export default HomePage;