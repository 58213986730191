import config from "../config/config.js";

const API_INTERFACE_URI = {
  GET_STATUS: `${config.apiUrl}/node-gi/reprocessing/status`,
  TRACK_BULK_STATUS:`${config.apiUrl}/node-gi/reprocessing/bulk/rule`,
  // GET_STATUS: `${config.apiUrl}/node-gi/reprocessing/status`,

};

export default API_INTERFACE_URI;
