import React, {  useRef,useState } from "react";
import TextInput from "./TextInput";
import Button from "./Button";
import Header from "./Header";
import apiService from "../services/service";
import "ag-grid-community/styles/ag-grid.css";
import "bootstrap/dist/css/bootstrap.css";
import "rsuite/dist/rsuite.min.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { dummy} from '../constants/constants'
import DataTable from "./DataTable";
import Notification from "./Notification";

const DBAudit = (props) => {
  const [cartonnumber, setCartonNumber] = useState("");
  const [ordernumber, setOrderNumber] = useState("");
  const [gridFilteredRules, setGridFilteredRules] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [notification, setNotification] = useState(null);
  const [uploadedFile, setUploadedFile]=useState("");
  const [errorCssMsg, setErrorCssMessage] = useState(false);


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "cartonnumber") {
      setCartonNumber(value);
    } else if (name === "ordernumber") {
      setOrderNumber(value);
    }
  };

  const handleFileUpload = (event) => {
  const selectedFile=event.target.files[0];
  setUploadedFile(selectedFile);
  console.log(selectedFile);
};

  const handleclear = () => {
    setCartonNumber("");
    setOrderNumber("");
    setErrorMsg("");
    setGridFilteredRules("");
  };

  const handleSubmit = async () => {
    try {
      const payload = {
        cartonNumber: cartonnumber,
        orderNumber: ordernumber,
      };
      const res = await apiService.getStatus(payload);
      console.log(res);
      if (res.status === 200) {
        console.log(res.data);
        // console.log(dummy)
        setGridFilteredRules(res.data);
      }
    } catch (e) {
      console.error("Error fethching status");
      if(e.status!==200&&e.status!==404)
      {
        setErrorMsg("Error while loading the data")
      }
      else if(e.status===404){
      if (ordernumber !== "" && cartonnumber === "") {
        setErrorMsg("Unable to fetch data ,Item Not found");
      }
      if (cartonnumber !== "" && ordernumber === "") {
        setErrorMsg("Unable to fetch data , Invalid Input");
      }
      if (ordernumber !== "" && cartonnumber !== "") {
        setErrorMsg("Unable to fetch data , Invalid Input");
      }}
    }
  };

  const handleCheckSts = async () => {
    try {
      console.log("uploaded file is " + uploadedFile);
      const formData = new FormData();
      formData.append('file', uploadedFile,uploadedFile.name);
      const response = await apiService.getBulkStatus(formData);
      console.log(response)
      if(response.status===200){
      let url = window.URL.createObjectURL(new Blob([response.data]), { type: 'application/vnd.ms-excel' })
      console.log(url, 'url===')
      const a = document.createElement('a');
      a.href = url;
      a.download = 'status_output.xlsx';
      document.body.appendChild(a)
      a.click();
      window.URL.revokeObjectURL(url);

      document.body.removeChild(a);

      showNotification("Output File Downloaded sucessfully!");
      setErrorCssMessage("notification-success");
      }
    } catch (e) {
      console.error("Error fethching status");
      console.log("******"+e.status)
      if(e.status!==200)
      {
        // setbulkErrorMsg("Error while loading the data");
        showNotification("Error while downloading output");
        setErrorCssMessage("notification-fail");

      }
      
    }
  };

const showNotification = (message)  => {
setNotification(message);
setTimeout(()=>{
  setNotification(null);
},5000);
};

  return (
    <div>
      <Header
        loggedInUser={props.loggedInUser}
        handleLogout={props.handleLogout}
        displayLogout={props.displayLogout}
        isHomePage={false}
      />
      <div className="display-flex justify-content-center margin-right-10px">
     <TextInput
          classes={{}}
          id="cartonnumber"
          label="Carton Number"
          name="cartonnumber"
          placeholder="Carton Number"
          supportText="cartonnumber"
          type="text"
          value={cartonnumber}
          onChange={handleInputChange}
        />
        <TextInput
          classes={{}}
          id="ordernumber"
          label="Order Number"
          name="ordernumber"
          placeholder="Order Number"
          supportText="ordernumber"
          type="text"
          value={ordernumber}
          onChange={handleInputChange}
        />
        <div className="button-container">
          
            <div className="action-component-btn button margin-right-10px" >
            <Button className="action-component-btn button margin-right-10px"
              name={"Submit"}
              classes={{}}
              appearance="primary"
              background="light"
              size="small"
              disabled={cartonnumber.trim() === "" && ordernumber.trim() === ""}
              type="button"
              disableRipple={false}
              onClick={handleSubmit}
            />
          </div>
          <div className="action-component-btn button margin-right-10px">
            <Button
              name={"Clear"}
              classes={{}}
              appearance="primary"
              background="light"
              size="small"
              disabled={false}
              type="button"
              disableRipple={false}
              onClick={handleclear}
            />
          </div></div>
      </div>
      <div className="upload-container">
          <div className="file-upload-container">
            <input 
            type="file" 
            accept=".xls,.xlsx"
            onChange={handleFileUpload}
           />
          </div>
         
            <div className="action-component-btn button-container">
            <Button className="action-component-btn button margin-right-2px"
              name={"Check Status"}
              classes={{}}
              appearance="primary"
              background="light"
              size="small"
              type="button"
              disableRipple={false}
              onClick={handleCheckSts}
            />
          </div>
          
   </div>
      {errorMsg && <div className="alert alert-danger" role="alert"style={{color:'red'}}>{errorMsg}<strong > ! </strong></div>}
      {!errorMsg && gridFilteredRules.length>0 && (
        <div >
          <div style={{textAlign:'center'}}>
            <div style={{display:'inline-block',textAlign:'left',marginBottom:'30px'}}>
              <DataTable tabledata={gridFilteredRules} />
            </div></div></div>
      )}
      {notification && <Notification errorcssmsg={errorCssMsg} message={notification}/>}
    </div>
  );
};

export default DBAudit;